import router from "../router";
import el from "element-ui/src/locale/lang/el";

const breadcrumbData = {
    namespaced:true,
    state:{
        tabsList: [
            {
                // 默认展示首页标签，所以给予一个初始标签
                // 每次点击菜单跳转的时候会修改数据，这样面包屑导航就可以读取到跳转时的路径，就可以显示出来，产生一个联动效果
                path:'/',
                name:'主页',
            },
        ],
    },
    actions:{
        selectMenu({ state, commit, dispatch, rootState },routeinfo) {
            state.tabsList=[{
                // 默认展示首页标签，所以给予一个初始标签
                // 每次点击菜单跳转的时候会修改数据，这样面包屑导航就可以读取到跳转时的路径，就可以显示出来，产生一个联动效果
                path:'/',
                name:'主页',
            },]
            if (routeinfo.name!=='主页'){
                const res = state.tabsList.findIndex(item=>item.name===routeinfo.name)
                if (res===-1){
                    if (routeinfo.parent){
                        const _res = state.tabsList.findIndex(item=>item.name===routeinfo.parent.name)
                        if (_res===-1){
                            state.tabsList.push(routeinfo.parent)
                            state.tabsList.push(routeinfo)
                        }
                    }else {
                        state.tabsList.push(routeinfo)
                    }
                }
            }
            console.log(state,'打印路由信息==============================')

        },
    },
    mutations:{

    }
}
export default breadcrumbData