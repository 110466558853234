import Vue from "vue";
import VueRouter from "vue-router";
import { tokenExpressInTime } from "../tools/utils";
import { Message } from "element-ui";
import { FOLLOW_ROUTES } from "./follow-manager";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register.vue"),
  },
  {
    path: "/",
    redirect: "login",
    component: () => import("@/views/Login.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: () => import("@/views/home.vue"),
    redirect: "/patienteducation",
    meta: {
      name: "主页",
    },
    children: [
      {
        path: "/welcome",
        component: () => import("@/views/welcome"),
      },
      {
        path: "/taskcenter",
        name: "taskcenter",
        component: () => import("@/views/taskcenter/taskcenter"),
        meta: {
          path: "/taskcenter",
          name: "随访管理",
        },
      },
      {
        path: "/reviewManagement",
        name: "reviewManagement",
        component: () => import("@/views/taskcenter/reviewManagement"),
        meta: {
          path: "/reviewManagement",
          name: "复查任务",
          parent: { path: "/taskcenter", name: "任务中心" },
        },
      },
      {
        path: "/ordermanage",
        name: "ordermanage",
        component: () => import("@/views/ordermanage/ordermanage"),
        meta: {
          path: "/ordermanage",
          name: "工单管理",
        },
      },
      {
        path: "/eventmanage",
        name: "eventmanage",
        component: () => import("@/views/eventmanagement/eventmanagement"),
        meta: {
          path: "/eventmanage",
          name: "事件管理",
        },
      },
      // {
      //   path: "/fillquestionnaire",
      //   name: "fillquestionnaire",
      //   component: () => import("@/views/taskcenter/fillquestionnaire"),
      //   meta: {
      //     path: "/fillquestionnaire",
      //     name: "填写问卷",
      //     parent: { path: "/taskcenter", name: "随访任务" },
      //   },
      // },
      {
        path: "/fillquestionnaire",
        name: "fillquestionnaire",
        component: () => import("@/views/taskcenter/fillquestionnaire2"),
        meta: {
          path: "/fillquestionnaire",
          name: "填写问卷",
          parent: { path: "/taskcenter", name: "随访任务" },
        },
      },
      ...FOLLOW_ROUTES,
      {
        path: "/followdetails",
        name: "followdetails",
        component: () => import("@/views/taskcenter/followdetails"),
        meta: {
          path: "/问卷详情",
          name: "随访详情",
          parent: { path: "/taskcenter", name: "随访任务" },
        },
      },
      {
        path: "/reviewdetails",
        name: "reviewdetails",
        component: () => import("@/views/taskcenter/reviewdetails"),
        meta: {
          path: "/reviewdetails",
          name: "复查详情",
          parent: { path: "/reviewManagement", name: "复查任务" },
        },
      },
      {
        path: "/editpatient",
        name: "editpatient",
        component: () => import("@/views/patientmanage/editpatient"),
        meta: {
          path: "/editpatient",
          name: "患者处理",
          parent: { path: "/newpatientdata", name: "患者管理" },
        },
      },
      {
        path: "/newpatientdata",
        name: "newpatientdata",
        component: () => import("@/views/patientmanage/newpatientdata"),
        meta: {
          path: "/newpatientdata",
          name: "患者数据",
          parent: { path: "/newpatientdata", name: "患者管理" },
        },
      },
      {
        path: "/patientlist",
        name: "patientlist",
        component: () => import("@/views/patientmanage/patientlist"),
        meta: {
          path: "/patientlist",
          name: "患者管理",
        },
      },
      {
        path: "/callingrecords",
        name: "callingrecords",
        component: () => import("@/views/callingrecords/callingrecords"),
        meta: {
          path: "/callingrecords",
          name: "呼叫记录",
        },
      },
      {
        path: "/patienteducation",
        name: "patienteducation",
        component: () => import("@/views/patienteducation/patienteducation"),
        meta: {
          path: "/patienteducation",
          name: "宣教中心",
        },
      },
    ],
  },
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  //如果访问的是登录页面直接放行
  if (to.path == "/login") return next();
  //获取储存的token值
  const token = window.sessionStorage.getItem("token");
  if (!token) {
    next("/login");
  } else {
    next();
  }
  //判断token是否过期
  if (tokenExpressInTime() && token) {
    Message({
      type: "error",
      center: true,
      message: "登录失效，请重新登录",
      duration: 2000,
    });
    next("/login");
  } else {
    next();
  }
});
export default router;
