const userInfo = {
    namespaced:true,
    state:{
        _userInfo:{},
    },
    actions:{

    },
    mutations:{
    }
}
export default userInfo