import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
import loginAbout from './login.js'
import userInfo from './userInfo.js'
import breadcrumbData from "./Breadcrumb";
import calling from "./calling";
export default new Vuex.Store({
  modules:{
    loginAbout,
    userInfo,
    breadcrumbData,
    calling
  }
})
