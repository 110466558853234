import HttAxios from "./index";

class Apis {
  /* static userGet(params){return new HttAxios("/config",params).get()}*/
  //登录接口
  static Login(params) {
    return new HttAxios("customerAuth/login", params).post();
  }
  //获取任务中心数据
  static getTaskCenterList(params) {
    return new HttAxios("taskCenter/findTaskCenterList", params).post();
  }
  //获取随访详情问题列表
  static getFollowUpQuestionsList(params) {
    return new HttAxios("taskCenter/findFollowUpQuestionsList", params).post();
  }
  //获取随访详情答案列表
  static getFollowUpAnswersList(params) {
    return new HttAxios("taskCenter/addQuestionAnswer", params).post();
  }

  // 查询工单管理已处理工单
  static findWorkList(params) {
    return new HttAxios("workManage/findWorkList", params).post();
  }
  // 查询工单管理未处理工单
  static findProcessedList(params) {
    return new HttAxios("workManage/findProcessedList", params).post();
  }

  // 查询工单管理数据详情(待处理)
  static getWorkManageDetails(params) {
    return new HttAxios("workManage/getWorkManageDetails", params).post();
  }

  static getAssistantGroups(params) {
    return new HttAxios("taskCenter/getAssistantGroups", params).post();
  }
  // 获取所有工作室名称

  // 查询工单管理数据详情(已处理)
  static getProcessedDetails(params) {
    return new HttAxios("workManage/getProcessedDetails", params).post();
  }

  // 编辑工单
  static updateWorkManage(params) {
    return new HttAxios("workManage/updateWorkManage", params).post();
  }
  //查询父问题关联的子问题及其可选选项
  static getsubsQuestions(params) {
    return new HttAxios("taskCenter/findLinkQuestion", params).post();
  }
  //获取所有工作室
  static getOfficeList(params) {
    return new HttAxios("taskCenter/getAssistantGroups", params).post();
  }
  //获取任务中心已处理患者列表
  static getDealedPatiensList(params) {
    return new HttAxios("taskCenter/findPendingList", params).post();
  }
  //（任务中心）添加工单
  static addWorkOrder(params) {
    return new HttAxios("workManage/addWorkManageData", params).post();
  }
  //提醒患者
  static remindPatient(params) {
    return new HttAxios("taskCenter/remindUser", params).post();
  }
  //随访问卷详情（包括所选答案）
  static getFollowUpdetails(params) {
    return new HttAxios("taskCenter/getQuestionsAndAnswers", params).post();
  }
  //根据条件查询患者(待分配)
  static getPatientsToAsigne(params) {
    return new HttAxios("userManage/findUserInfoLinkSeller", params).post();
  }
  //根据条件查询患者(已分配)
  static getPatientsAsigned(params) {
    return new HttAxios("userManage/findAssignedUserInfo", params).post();
  }
  //今日新增患者     userManage/findUserInfoAndNumToDay
  static getTodayNewPatients(params) {
    return new HttAxios("userManage/findUserInfoAndNumToDay", params).post();
  }
  //(分片)查询新增患者数据（近七日
  static getDay7NewPatients(params) {
    return new HttAxios("userManage/findUserInfoAndNumDay7", params).post();
  }
  //(分片)查询新增患者数据（近一月）
  static getcurrentMontsNewPatients(params) {
    return new HttAxios("userManage/findUserInfoAndNumMonth", params).post();
  }
  //(分片)查询新增患者数据（近一年
  static getcurrentYearNewPatients(params) {
    return new HttAxios("userManage/findUserInfoAndNumYear", params).post();
  }
  //分配患者
  static dispatchPatiens(params) {
    return new HttAxios("userManage/assignUserLinkSeller", params).post();
  }
  //获取患者信息
  static getPatientInfoMation(params) {
    return new HttAxios("taskCenter/getUserInfo", params).post();
  }
  //填写问卷保存提交
  static submitQuestionnaire(params) {
    return new HttAxios("taskCenter/addQuestionAnswer", params).post();
  }
  //获取的对应市下的医院和工作室
  static getDoctorGroupNames(params) {
    return new HttAxios(
      "userManage/getDoctorGroupNamesByRegionName",
      params
    ).post();
  }

  //获取复查待处理数据
  static getTaskcenterReviewList(params) {
    return new HttAxios("taskCenter/findReviewList", params).post();
  }
  //获取复查已处理处理数据
  static getDealedTaskcenterReviewList(params) {
    return new HttAxios("taskCenter/getReviewList", params).post();
  }
  //提醒患者复查
  static remindToReview(params) {
    return new HttAxios("taskCenter/remindReviewUser", params).post();
  }

  //获取复查详情
  static getReviewDetails(params) {
    return new HttAxios("taskCenter/getReviewInfo", params).post();
  }

  //获取提醒
  static getRemindRecords(params) {
    return new HttAxios("taskCenter/getHistoryByType", params).post();
  }

  //开始提醒
  static remindRelations(params) {
    return new HttAxios("taskCenter/addRemindByType", params).post();
  }

  //获取通话记录
  static getCallingRecords(params) {
    return new HttAxios("callCenter/getHistoryList", params).post();
  }

  //查看通话记录详情
  static getCallingDetail(params) {
    return new HttAxios("callCenter/getHistoryInfo", params).post();
  }
  //查看查询来电姓名
  static getCallingName(params) {
    return new HttAxios("callCenter/getNameByPhone", params).post();
  }

  //获取坐席队列
  static getSitStatus() {
    return new HttAxios("callCenter/getQueueList").get();
  }

  //根据坐席号查询电话列表
  static getCallingPhoneList(params) {
    return new HttAxios("callCenter/getPhoneListByCno", params).post();
  }

  //手机号搜索符合要求的患者列表
  static getCallingPatientsList(params) {
    return new HttAxios("callCenter/getUserListByKeyword", params).post();
  }

  //根据id查亲属关系
  static getRelationsPhone(params) {
    return new HttAxios("callCenter/getPatientList", params).post();
  }

  //获取该客服关联的所有工作室名称
  static getGroupNameFromCustomer(params) {
    return new HttAxios("taskCenter/getGroupName", params).post();
  }

  //获取该客服关联的所有医助名称
  static getAssistantName(params) {
    return new HttAxios("taskCenter/getAssistantName", params).post();
  }
  //获取该客服关联的所有医院名称
  static getHospitalName(params) {
    return new HttAxios("taskCenter/getHospitalName", params).post();
  }
  //宣教列表
  static getTeachList(params) {
    return new HttAxios("taskCenter/getTeachList", params).post();
  }

  //发起宣教
  static updateTeach(params) {
    return new HttAxios("taskCenter/updateTeach", params).patch();
  }
  //下次提醒随访
  static updateRemindFollowUp(params) {
    return new HttAxios("taskCenter/updateFollowUp", params).patch();
  }
  static updateRemindReview(params) {
    return new HttAxios("taskCenter/updateReview", params).patch();
  }
  //获取工单管理
  static getWorkManageInfo(params) {
    return new HttAxios("taskCenter/getWorkManageInfo", params).post();
  }

  //获取所有患者
  static getAllPatient(params) {
    return new HttAxios("userManage/getAllUserInfo", params).post();
  }

  //获取 手机验证码
  static getVerifyPhoneCode(params) {
    return new HttAxios("customerAuth/sendPhoneCode", params).post();
  }
  //获取 登录方式
  static getLoginType(params) {
    return new HttAxios("customerAuth/getLoginType", params).post();
  }
  //获取事件列表
  static getEventListByType(params) {
    return new HttAxios("event/getListByType", params).post();
  }
  //获取事件处理详情接口
  static getEventProcessingInfo(params) {
    return new HttAxios("event/getProcessingInfo", params).post();
  }
  //获取患者及成员电话号码
  static getPatientPhones(params) {
    return new HttAxios("event/getPatientList", params).post();
  }
  //处理血压血糖事件
  static dealPatientEvent(params) {
    return new HttAxios("event/addPatientEvent", params).post();
  }

  // 患者管理重构分割线-----

  // 获取用户画像信息
  static getImageInfo(params) {
    return new HttAxios("taskCenter/imageInfo", params).post();
  }
  // 获取用户高危因素、依从性
  static getHighRiskFactor(params) {
    return new HttAxios("taskCenter/highRiskFactor", params).post();
  }
  //随访详情查询患者问卷列表
  static getUserQuestionnaireList(params) {
    return new HttAxios("taskCenter/getUserQuestionnaireList", params).post();
  }

  //随访详情查询患者已完成问卷列表
  static getPendingDetails(params) {
    return new HttAxios("/taskCenter/getPendingDetails", params).post();
  }

  // 提交生活问卷
  static saveWriteUserQuestionnaire(params) {
    return new HttAxios("taskCenter/writeUserQuestionnaire", params).post();
  }

  // 无法联系到患者隐藏随访问卷、生活方式评估问卷
  static updataHiddenFollowUp(params) {
    return new HttAxios("taskCenter/hiddenFollowUp", params).patch();
  }

  // 查询患者只填写一次的相关问卷问题数据
  static getQuRuleInfo(params) {
    return new HttAxios("taskCenter/getQuRuleInfo", params).post();
  }
}

export default Apis;
