import $http from "../HTTP/apis";
import { Message } from "element-ui";
import router from "../router";

const calling = {
    namespaced:true,
    state:{
        isLogin:0 ,//0未登录,1已登录,
        isCall:false, //false,通话中,true未通话,
        isCallInRinging:0, //0,未响铃,1未响中,
        callType:'2',//1忙碌,2空闲,
        dialogVisibleTelephoneCalls:false, //控制弹屏
        timmer:null,
        callName: "", // 打入电话人姓名
        callPhone: "" // 打入电话号码
    },
    actions:{
        initCallingState({ state, commit, dispatch, rootState }, params) {
            ClinkAgent.setup({ sipPhone: true, debug: false }, function() {
                console.log("//回调操作");
                // 注册登录响应回调方法
                ClinkAgent.registerListener(ClinkAgent.EventType.STATUS, function(e) {
                    console.log("//坐席状态", e);
                    if (e.code==="IDLE"){
                        state.isCall = false
                        state.callType = '2'
                        state.isCallInRinging = 0
                        state.dialogVisibleTelephoneCalls = false
                        clearTimeout(state.timmer)
                    }
                    if (e.code==="CALLING"){
                        state.callType = '呼叫中'
                    }
                    if (e.code==="RINGING" && e.action==='ringingAgentOb'){
                        state.callType = '外呼响铃'
                    }
                    if (e.code==="RINGING" && (e.action==='ringingIb' || e.action==='ringingTransfer')){
                        state.callType = '来电响铃'
                        state.isCallInRinging=1
                        state.callPhone = e.customerNumber;
                        $http.getCallingName({phone:e.customerNumber}).then(res=>{
                            console.log('===========来电姓名=============')
                            state.callName=res.result.data.name
                        }).catch(err=>{
                            console.log(err)
                        })
                        //15秒后弹屏
                        state.timmer = setTimeout(()=>{
                            console.log('执行弹屏')
                            state.dialogVisibleTelephoneCalls = true
                        },15000)
                    }
                    if (e.code==="BUSY" && e.action==="busyAgentOb"){
                        state.callType = '外呼通话'
                        state.isCall = true
                    }
                    if (e.code==="BUSY" && (e.action==="busyIb" || e.action==="busyTransfer")){
                        state.callType = '通话中'
                        state.isCall = true
                        state.isCallInRinging = 0

                        state.dialogVisibleTelephoneCalls = false
                        clearTimeout(state.timmer)
                    }
                    if (e.code==="WRAPUP"){
                        state.callType = '整理中'
                        state.isCall = false
                        state.isCallInRinging = 0
                    }
                });
                // 回调操作
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.LOGIN, function(e) {
                    console.log("//登录状态", e);
                    if (e.code===0){
                        console.log('===========电话登录成功==============')
                        state.isLogin = 1
                        Message.success({
                            message:'电话登录成功!',
                            duration:1000
                        })
                    }else {
                        console.log('===========电话登录失败==============')
                        state.isLogin = 0
                        Message.error({
                            message:`电话登录失败${e.msg}!`,
                            duration:1000
                        })
                    }
                }); // 登录

                ClinkAgent.registerCallback(ClinkAgent.ResponseType.LOGOUT, function(
                    event
                ) {
                    console.log("//退出", event);
                }); //退出

                ClinkAgent.registerCallback(ClinkAgent.ResponseType.PAUSE, function(
                    event
                ) {
                    console.log("//置忙", event);
                    if (event.code===0){
                        console.log('===========置忙成功==============')
                        state.callType = '1'
                        Message.success('置忙成功!!')
                    }else {
                        console.log('===========置忙失败==============')
                        Message.error('置忙失败!')
                    }
                }); // 置忙

                ClinkAgent.registerCallback(ClinkAgent.ResponseType.UNPAUSE, function(
                    event
                ) {
                    if (event.code===0){
                        console.log('===========置闲成功==============')
                        state.callType = '2'
                        Message.success('置闲成功!!')
                    }else {
                        console.log('===========置闲失败==============')
                        Message.error('置闲失败!')
                    }
                }); // 置闲
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.PREVIEW_OUTCALL,
                    function(result) {
                        if (result.code === 0) {
                            console.log("电话呼出成功", result);
                            state.isCall = true
                        } else {
                            console.log("电话呼出失败", result);
                            state.isCall = false
                        }
                    }
                ); // 预览外呼
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.PREVIEW_OUTCALL_CANCEL, function(event) {
                        console.log("//外呼取消", event);
                    }
                ); // 外呼取消
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.REFUSE, function(event) {
                    console.log("//拒接", event);
                }); // 拒接

                ClinkAgent.registerCallback(ClinkAgent.ResponseType.UNLINK, function(event) {
                    console.log("//挂断", event);
                }); // 挂断

                //预览外呼响铃
                ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL_RINGING,  function(event) {
                    console.log("预览外呼响铃", event);
                    if (event.code==="BUSY" && event.action==="ringingCustomerOb"){
                        state.callType = '响铃中...'
                        state.isCall = true
                    }
                })

                ClinkAgent.registerListener(ClinkAgent.EventType.PREVIEW_OUTCALL_BRIDGE,  function(event) {
                    console.log("预览外呼客户接听", event);
                    if (event.code==="BUSY" && event.action==="busyOb"){
                        state.callType = '通话中'
                        state.isCall = true
                    }
                })
                // 静音
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.MUTE, function(event) {
                    console.log("//静音", event);
                    if (event.code===0){
                        console.log('===========置闲成功==============')
                        Message.success('静音成功!!')
                    }else {
                        console.log('===========置闲失败==============')
                        Message.error('静音失败!')
                    }
                })
                //取消静音
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.UNMUTE, function(event) {
                    console.log("取消静音", event);
                    if (event.code===0){
                        console.log('===========置闲成功==============')
                        Message.success('取消静音成功!!')
                    }else {
                        console.log('===========置闲失败==============')
                        Message.error('取消静音失败!')
                    }
                });
                // 保持
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.HOLD, function(event) {
                    console.log('===========保持通话成功==============')
                    if (event.code===0){
                        console.log('===========置闲成功==============')
                        Message.success('保持通话成功!!')
                    }else {
                        console.log('===========置闲失败==============')
                        Message.error('保持通话失败!')
                    }
                });
                // 保持接回
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.UNHOLD, function(event) {
                    console.log('===========保持接回通话成功==============')
                    if (event.code===0){
                        console.log('===========置闲成功==============')
                        Message.success('保持接回通话成功!!')
                    }else {
                        console.log('===========置闲失败==============')
                        Message.error('保持接回通话失败!')
                    }
                });
                // 软电话接听
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.SIP_LINK, function(event) {

                });
                // 软电话挂断
                ClinkAgent.registerCallback(ClinkAgent.ResponseType.SIP_UNLINK, function(event) {

                });
            });
        },
        //电话登录
        loginCalling({state, commit, dispatch, rootState},params){
            ClinkAgent.login(params);
        },
        //静音
        forbidVoice({state, commit, dispatch, rootState},params){
            ClinkAgent.mute(params)
        },
        //取消静音
        cancelForbidVoice({state, commit, dispatch, rootState},params){
            ClinkAgent.unmute(params)
        },
        //置忙
        callingPause({state, commit, dispatch, rootState},params){
            ClinkAgent.pause({pauseType:'1'})
        },
        //置闲
        callingUnPause({state, commit, dispatch, rootState},params){
                ClinkAgent.unpause({})
        },
        //更改通话状态,用于页面展示
        // changeCallType(){
        //
        // }
        //满意度调查
        investigationCalling({state, commit, dispatch, rootState},params){
            ClinkAgent.investigation(params)
        },
        //保持通话
        holdPhone({state, commit, dispatch, rootState},params){
            ClinkAgent.hold(params)
        },
        //保持通话接回
        holdReturnPhone({state, commit, dispatch, rootState},params){
            ClinkAgent.unhold(params)
        },
        //软电话接听
        sipLink({state, commit, dispatch, rootState},params){
            ClinkAgent.sipLink()
        },
        //软电话挂断
        sipUnlink({state, commit, dispatch, rootState},params){
            ClinkAgent.sipUnlink()
        }
    },
    mutations:{

    }
}
export default calling