/* eslint-disable */
/*
    json传值  headers:{"Content-Type":"application/json;charset=UTF-8"}   JSON.stringify(data)
    return JSON.stringify(data)
    form传值  headers:{"Content-Type":" application/x-www-form-urlencoded"}
    var dataArr=[]
    for(let i in data){dataArr.push(`${i}=${data[i]}`)}
    return dataArr.join("&")
*/
import crypto from "../common/AES";
//用于继承
const OPENCRYPTO = process.env.NODE_ENV == "production"; //打开加密的开关，默认是production环境打卡，其他环境关闭。

class HttpHandlers {
  // 添加请求拦截器
  interceptorsReqBefor(config) {
    // 在发送请求之前做些什么
    const token = window.sessionStorage.getItem("token");
    // 添加token到请求
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    console.log(config["data"], "请求之前的参数=======================");
    // config['data'] = { value: crypto.encrypt(JSON.stringify(config['data'])) }//加密，上线时使用
    //去除加密
    console.log(OPENCRYPTO);
    config["data"] = OPENCRYPTO
      ? { value: crypto.encrypt(JSON.stringify(config["data"])) }
      : { value: config["data"] };
    return config;
  }
  interceptorsReqErr(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
  // 添加响应拦截器
  interceptorsResBefor(response) {
    // 对响应数据做点什么
    if (OPENCRYPTO) {
      console.log(response);
      if (response.data.result && response.data.result.code == "1") {
        response.data.result.data = response.data.result.data
          ? JSON.parse(crypto.decrypt(response.data.result.data))
          : response.data.result.data;
        console.log(
          response.data.result.data,
          "响应数据========================"
        );
        return response;
      } else {
        return response;
      }
    } else {
      return response;
    }
    // if (response.data.result && response.data.result.code == '1') {
    //     response.data.result.data = response.data.result.data ? JSON.parse(crypto.decrypt(response.data.result.data)) : response.data.result.data
    //     console.log(response.data.result.data,'响应数据========================')
    //     return response
    // }
    // return response
  }
  interceptorsResErr(error) {
    // 对响应数据做点什么
    console.log(error);
    return Promise.reject(error);
  }
  //`transformRequest` 允许在向服务器发送前，修改请求数据
  transformRequest(data) {
    return JSON.stringify(data);
  }
  //`transformResponse` 在传递给 then/catch 前，允许修改响应数据
  transformResponse(data) {
    return JSON.parse(data);
  }
}

export default HttpHandlers;
