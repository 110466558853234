import CryptoJS from "crypto-js"


export default {

    //随机生成指定数量的16进制key
    0(num) {

        let library = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        let key = "";
        for (var i = 0; i < num; i++) {
            let randomPoz = Math.floor(Math.random() * library.length);
            key += library.substring(randomPoz, randomPoz + 1);

        }

        return key;

    },



    //加密

    encrypt(word, keyStr) {
        // console.log(word,keyStr,'///++');

        // secretKey:7kSVwvjDkJkVTYTf
        // secretId:O0LccqG811e3w0A8
        keyStr = keyStr ? keyStr : '7kSVwvjDkJkVTYTf'

        var key = CryptoJS.enc.Utf8.parse(keyStr);

        var iv = CryptoJS.enc.Utf8.parse('O0LccqG811e3w0A8');//十六位十六进制数作为秘钥偏移量(ABCDEF1234123412)

        var srcs = CryptoJS.enc.Utf8.parse(word);


        var encrypted = CryptoJS.AES.encrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

        // return encrypted.ciphertext.toString().toUpperCase();
        return encrypted.toString();

    },

    //解密

    decrypt(word, keyStr) {
        // console.log(word,keyStr,'///');
        keyStr = keyStr ? keyStr : '7kSVwvjDkJkVTYTf'

        var key = CryptoJS.enc.Utf8.parse(keyStr);

        var iv = CryptoJS.enc.Utf8.parse('O0LccqG811e3w0A8');//十六位十六进制数作为秘钥偏移量(ABCDEF1234123412)

        // var encryptedHexStr = CryptoJS.enc.Hex.parse(word);

        // var srcs = CryptoJS.enc.Base64.stringify(encryptedHexStr);
        var srcs = word;

        var decrypt = CryptoJS.AES.decrypt(srcs, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });

        var decryptedStr = decrypt.toString(CryptoJS.enc.Utf8);

        return decryptedStr.toString();

    }

}