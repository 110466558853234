import axios from "axios";
import Handlers from "./handlers";

class HttAxios extends Handlers {
  instance = null;
  baseOptions = {
    baseURL: process.env.VUE_APP_baseUrl,
    timeout: 10000,
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
      "Accept-Language": "zh-CN,zh;q=0.9,en;q=0.8",
    },
    validateStatus: function (status) {
      return status >= 200 && status < 300; // default
    },
  };

  router = "";
  params = "";

  constructor(router, params, options) {
    //接口是否要带token:true直接放options
    super();
    //转换器添加
    this.baseOptions.transformRequest = [this.transformRequest];
    this.baseOptions.transformResponse = [this.transformResponse];

    Object.assign(this.baseOptions, options);
    this.router = router;
    this.params = params;

    var _instance = axios.create(this.baseOptions);
    //拦截器添加
    _instance.interceptors.request.use(
      this.interceptorsReqBefor,
      this.interceptorsReqErr
    );
    _instance.interceptors.response.use(
      this.interceptorsResBefor,
      this.interceptorsResErr
    );

    this.instance = _instance;
  }

  get() {
    return new Promise((resolve, reject) => {
      this.instance
        .get(this.router, { params: this.params })
        .then((result) => {
          resolve(result.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  post() {
    return new Promise((resolve, reject) => {
      this.instance
        .post(this.router, this.params)
        .then((result) => {
          console.log(result,'=========')
          if (result.data.result.code!==1){
            reject(result.data.result)
          }else {
            resolve(result.data);
          }
        })
        .catch((err) => {
          console.log(err,'=========')
          reject(err);
        });
    });
  }

  patch() {
    return new Promise((resolve, reject) => {
      this.instance
          .patch(this.router, this.params)
          .then((result) => {
            if (result.data.result.code!==1){
              reject(result.data.result)
            }else {
              resolve(result.data);
            }
          })
          .catch((err) => {
            console.log("post------>", err);
            reject(err);
          });
    });
  }
}

export default HttAxios;
