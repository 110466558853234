import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入饿了么样式
import './plugins/element.js'
//导入全局样式表
import './assets/css/global.css'
import './assets/css/inconfont.css'
import {toPages} from "./tools/utils";
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
// 引入echarts
import * as echarts from 'echarts'
import moment from 'moment'
import $http from './HTTP/apis'
import Breadcrumb from "./components/Breadcrumb";
Vue.component('Breadcrumb',Breadcrumb)
Vue.use(Viewer, {
  defaultOptions: {
    inline: false,
    fullscreen: false,
    container: 'body>div'
  }
})
Vue.prototype.$http = $http
Vue.prototype.$moment = moment
Vue.prototype.$echarts = echarts
Vue.prototype.$to = toPages
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
