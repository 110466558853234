<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <!--    <el-breadcrumb-item :to="{ path: '' }">主页</el-breadcrumb-item>-->
      <!--    <el-breadcrumb-item>任务中心</el-breadcrumb-item>-->
      <!--    <el-breadcrumb-item>复查任务</el-breadcrumb-item>-->
      <el-breadcrumb-item
        v-for="(item, index) in breadList"
        :key="index"
        :to="item.disable ? null : { path: `${item.path}` }"
        >{{ item.name }}</el-breadcrumb-item
      >
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {};
  },
  mounted() {
    console.log(this.$store);
  },
  computed: {
    breadList() {
      console.log(this.$store.state.breadcrumbData.tabsList);
      let arr = this.$store.state.breadcrumbData.tabsList;
      arr[arr.length - 1]["disable"] = true;
      return arr;
    },
  },
};
</script>

<style scoped></style>
