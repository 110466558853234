<template>
  <div id="app">
    <!-- 路由占位符 -->
      <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  watch: {
    $route (to, from) { //to:即将要跳转到的页面   from:即将离开的页面
      console.log(to.meta)
      this.$store.dispatch('breadcrumbData/selectMenu',to.meta)
    }
  },
  created() {
    this.$store.dispatch("calling/initCallingState")

    //默认登录软电话
    window.onbeforeunload = function(e) {
      return '确定离开此页吗？';
    }
  },
  mounted() {
    this.checkedTimeOut()
  },
  methods:{
    checkedTimeOut(){
      let _that = this
      let isMove = false, timer = null;
      window.onmousemove = function () {
        isMove = true;
        clearTimeout(timer);
        timer = setTimeout(function () {
          isMove = false;
          console.log('静止后',isMove); // 静止后
          console.log('你已经有5秒没有动了')
          if(_that.$router.currentRoute.path === '/login') return
          _that.$store.dispatch('loginAbout/loginOut')
          _that.$message.warning('长时间未操作,已登出!')
        }, 3600000);
      }
    }
  },
  destroyed() {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  }
}
</script>

<style>

</style>
