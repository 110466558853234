/* eslint-disable */
import { Message } from "element-ui";
import $http from "../HTTP/apis";
import router from "../router";
import Vue from "vue";
const loginAbout = {
  namespaced: true,
  actions: {
    // 登录
    login({ state, commit, dispatch, rootState }, loginForm) {
      console.log(loginForm);
      return new Promise((resolve, reject) => {
        $http
          .Login(loginForm)
          .then((res) => {
            console.log(res,'===========登录信息==============')
            if (res.result.code !== 1) {
              Message.error("登录失败");
              return reject("登陆失败");
            } else {
              Message.success("登录成功");
              window.sessionStorage.setItem("token", res.result.data.token);
              const userInfo = JSON.stringify(res.result.data.userInfo);
              window.sessionStorage.setItem("userInfo", userInfo);
              let date = new Date().getTime() + (10 * 3600 * 1000)
              localStorage.setItem('tokenTime', date)
              router.push("/taskcenter");
              return resolve(res);
            }
          })
          .catch((error) => {
            console.log(error)
            Message.error(`登录失败:${error.msg}`);
            return reject("登陆失败");

          });
      });
    },
    //登出事件
    loginOut({ state, commit, dispatch, rootState }) {
      window.sessionStorage.clear();
      router.push("/login");
      window.localStorage.clear()
    },
    //获取手机验证码
    getVerifyCode({ state, commit, dispatch, rootState }, data){
      $http.getVerifyPhoneCode(data).then(res=>{
        Message.success("验证码发送成功!");
      }).catch(err=>{
        Message.error(`验证码发送失败:${err.msg}`);
      })

    }
  },
  mutations: {},
  state: {},
  getters: {},
};
export default loginAbout;
