export const FOLLOW_ROUTES = [
  {
    path: "/followUpDetail",
    name: "FollowUpDetail",
    component: () => import("@/views/taskcenter/followUpDetail.vue"),
    meta: {
      path: "/fillquestionnaire",
      name: "随访详情",
      parent: { path: "/taskcenter", name: "随访任务" },
    },
  },
];
