import router from "../router";
import moment from "moment";
import Vue from "vue";

export default {
  $toInt(str) {
    return parseInt(str);
  },
};

//简单封装路由跳转
export function toPages(name, query) {
  if (query) {
    router.push({ name, query });
  } else {
    router.push({ name });
  }
}

//Vue全局时间过滤器过滤器
Vue.filter("dateFormat", function (originDate, format) {
  return moment(originDate).format(format);
});
Vue.filter("dateSpecialFormat", function (originDate, format) {
  // return moment(originDate, [format, moment.ISO_8601])
  return moment(originDate).format(format);
});

//时间处理
//时间处理
export function timeMode(time, symbol) {
  symbol = symbol ? symbol : "-";

  function timeHandle(time) {
    let dates = new Date(time);
    let year = String(dates.getFullYear());
    let month =
      String(dates.getMonth() + 1) < 10
        ? "0" + String(dates.getMonth() + 1)
        : String(dates.getMonth() + 1);
    let date =
      String(dates.getDate()) < 10
        ? "0" + String(dates.getDate())
        : String(dates.getDate());
    let hour =
      String(dates.getHours()) < 10
        ? "0" + String(dates.getHours())
        : String(dates.getHours());
    let minutes =
      String(dates.getMinutes()) < 10
        ? "0" + String(dates.getMinutes())
        : String(dates.getMinutes());
    let seconds =
      String(dates.getSeconds()) < 10
        ? "0" + String(dates.getSeconds())
        : String(dates.getSeconds());
    return { year, month, date, hour, minutes, seconds };
  }
  let { year, month, date, hour, minutes, seconds } = timeHandle(time);
  let datestr = year + symbol + month + symbol + date;
  let getStartTime = year + symbol + month + symbol + date + " " + "00:00";
  let getEndTime = year + symbol + month + symbol + date + " " + "23:59";
  let dateMin =
    year +
    symbol +
    month +
    symbol +
    date +
    " " +
    hour +
    ":" +
    minutes +
    ":" +
    seconds;
  let dateMinse =
    year + symbol + month + symbol + date + " " + hour + ":" + minutes;
  let curData = hour + ":" + minutes + ":" + seconds;

  let monthStartDay = timeHandle(new Date(year, month - 1, 1)).date;
  let monthEndDay = timeHandle(new Date(year, month, 0)).date;
  //本月的开始时间
  var monthStartDate = year + symbol + month + symbol + monthStartDay;
  //本月的结束时间
  var monthEndDate = year + symbol + month + symbol + monthEndDay;

  return {
    datestr,
    getStartTime,
    getEndTime,
    dateMin,
    dateMinse,
    monthStartDate,
    monthEndDate,
    curData,
  };
}
//计算年龄
export function jsGetAge(strBirthday) {
  var returnAge;
  var strBirthdayArr = strBirthday.split("-");
  var birthYear = strBirthdayArr[0];
  var birthMonth = strBirthdayArr[1];
  var birthDay = strBirthdayArr[2];

  var d = new Date();
  var nowYear = d.getYear();
  var nowMonth = d.getMonth() + 1;
  var nowDay = d.getDate();

  if (nowYear == birthYear) {
    returnAge = 0; //同年 则为0岁
  } else {
    var ageDiff = nowYear - birthYear; //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        var dayDiff = nowDay - birthDay; //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth; //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
    }
  }

  return returnAge; //返回周岁年龄
}

//监听token过期的方法
export function tokenExpressInTime() {
  let date = new Date();
  let tokenTime = localStorage.getItem("tokenTime");
  // 当前时间减去获取本地过期时间的值是否大于0   大于0为过期返回true 否则返回false
  return date.getTime() - Number(tokenTime) > 0 ? true : false;
}
//全局过滤器
Vue.filter("ellipsis", function (value) {
  if (!value) return "";
  if (value.length > 8) {
    return value.slice(0, 8) + "...";
  }
  return value;
});

Vue.filter("filterHtml", function (htmlStr) {
  if (!htmlStr) return "";

  let res = htmlStr.replace(/<\/?.+?\/?>|\r|\n|\s*/g, "");

  if (res.length > 15) {
    return res.slice(0, 15) + "...";
  }
  return res;
});

/**
 * vue防抖，兼容vue3,vue2和普通js
 * delay：延迟时间（毫秒）
 * **/

export class Debounce {
  constructor(delay) {
    this.delay = delay ? delay : 500;
    this.timeOut = null;
  }
  debounceEnd() {
    return new Promise((resolve, reject) => {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        resolve("success");
      }, this.delay);
    });
  }
}

// 排序
// type:1为从小到大，2为从大到小，默认为1
export function compare(property, type = 1) {
  return function (a, b) {
    if (type == 1) {
      return a[property] - b[property];
    } else {
      return b[property] - a[property];
    }
  };
}
